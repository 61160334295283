import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { differenceInSeconds, add } from 'date-fns';
import {
  ConnectSocketAdditionalParams,
  DeckType,
  TcgDisconnectedData
} from '../../../../types/types';
import { useAuth } from '../../../../contexts/AuthContext';

type ReconnectToGameComponentProps = {
  userDisconnectedGame: TcgDisconnectedData;
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  selectedDeckName: string;
  userDecks: DeckType[];
  setSelectedDeckName: (value: string) => void;
  resetGame: () => void;
};

export const ReconnectToGameComponent = ({
  userDisconnectedGame,
  connectSocket,
  selectedDeckName,
  userDecks,
  setSelectedDeckName,
  resetGame
}: ReconnectToGameComponentProps) => {
  const { accessToken } = useAuth();
  const [disconnectCountdownTimer, setDisconnectCountdownTimer] =
    useState<number>(0);

  useEffect(() => {
    if (userDisconnectedGame.timeLeft) {
      setDisconnectCountdownTimer(
        differenceInSeconds(
          add(userDisconnectedGame.timeLeft, { seconds: 60 }),
          new Date()
        )
      );
    }
  }, [userDisconnectedGame.timeLeft]);

  useEffect(() => {
    const countDown = setTimeout(() => {
      setDisconnectCountdownTimer(disconnectCountdownTimer - 1);
    }, 1000);

    return () => {
      clearTimeout(countDown);
    };
  }, [disconnectCountdownTimer]);

  const playAgainHandler = (botGame: boolean) => {
    resetGame();
    connectSocket({
      botGame,
      deckName: selectedDeckName,
      token: accessToken ?? ''
    });
  };

  return (
    <Box sx={{ py: '1rem', width: '350px' }}>
      <Typography
        variant="h5"
        fontWeight="bold"
        textAlign="center"
        sx={{ pb: '1rem' }}
      >
        Ongoing Match Found
      </Typography>

      <Divider />

      {disconnectCountdownTimer <= 0 ? (
        <Typography textAlign="center" fontWeight="bold" sx={{ p: '1rem' }}>
          You have failed to reconnect to an existing game you were in within 60
          seconds. You have lost your match.
        </Typography>
      ) : (
        <Typography textAlign="center" fontWeight="bold" sx={{ p: '1rem' }}>
          You have {disconnectCountdownTimer} seconds to reconnect to your
          ongoing game. Failure to reconnect will result in a loss for you.
        </Typography>
      )}

      <Divider />

      {disconnectCountdownTimer <= 0 ? (
        <Box>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            sx={{ p: '1rem' }}
          >
            Play Again?
          </Typography>

          <Divider />

          <Box
            sx={{
              pt: '1rem',
              display: 'flex',
              justifyContent: 'center',
              px: '1rem'
            }}
          >
            <FormControl sx={{ width: '250px' }}>
              <InputLabel id="deck-select-label">Select Deck</InputLabel>
              <Select
                labelId="deck-select-label"
                id="deck-select"
                label="Select Deck"
                onChange={(e) => {
                  setSelectedDeckName(e.target.value);
                }}
                value={selectedDeckName}
              >
                {userDecks.map((deck, index) => {
                  return (
                    <MenuItem key={index} value={deck.deckName}>
                      {deck.deckName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              pt: '1rem',
              px: '1rem',
              display: 'flex',
              justifyContent: 'space-evenly'
            }}
          >
            <Button
              size="large"
              variant="contained"
              onClick={() => playAgainHandler(false)}
              sx={{ width: '100px' }}
            >
              Users
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() => playAgainHandler(true)}
              sx={{ width: '100px' }}
            >
              Bot
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: '1rem' }}>
          <Button
            size="large"
            disabled={disconnectCountdownTimer <= 0}
            variant="contained"
            onClick={() =>
              connectSocket({
                botGame: userDisconnectedGame.gameType === 'bot',
                deckName: selectedDeckName,
                token: accessToken ?? ''
              })
            }
            sx={{ width: '150px' }}
          >
            Reconnect
          </Button>
        </Box>
      )}
    </Box>
  );
};
