import React from 'react';
import { Box, Button, Typography } from '@mui/material';

type GameMessageScreenProps = {
  title: string;
  message?: string;
  cancelSearch: () => void;
};

export const GameMessageScreen = ({
  title,
  message,
  cancelSearch
}: GameMessageScreenProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - 4rem)`,
        width: '100%',
        px: '2rem'
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="h3" fontWeight="bold" textAlign="center">
          {title}
        </Typography>
        {message && (
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            sx={{ pt: '1rem' }}
          >
            {message}
          </Typography>
        )}

        {title === 'Finding Match' && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              size="large"
              sx={{ mt: '2rem' }}
              onClick={() => cancelSearch()}
            >
              Cancel Search
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
