import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import gameBackgroundImage from '../../../../../assets/Images/game_background.png';
import {
  CardGameUserState,
  CardGameOpponentState,
  WarriorAndInfluencerCardDataType,
  TokenCardDataType,
  CardGamePlayerStatusEffects,
  StatusEffectData
} from '../../../../../types/types';
import { OpponentHud } from './OpponentHud';
import { PlayerHud } from './PlayerHud';
import { PlayerHand } from './PlayerHand';
import { PlayerCardBattleArea } from './PlayerCardBattleArea';
import { OpponentCardBattleArea } from './OpponentCardBattleArea';
import { add, differenceInSeconds } from 'date-fns';

type MobileTcgViewProps = {
  username: string;
  userState: CardGameUserState | null;
  opponentUsername: string;
  opponentState: CardGameOpponentState | null;
  selectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  opponentSelectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  onCardSubmit: () => void;
  roundStartTimer: Date | null;
  countdownTimer: number;
  setCountdownTimer: (value: number) => void;
  hasOpponentDisconnected: boolean;
  selectedTokenCard: TokenCardDataType | 'noCard' | null;
  setSelectedTokenCard: (card: TokenCardDataType | 'noCard' | null) => void;
  opponentSelectedTokenCard: TokenCardDataType | 'noCard' | null;
  disableCardSelection: boolean;
  playerCardFinalAttack: number | null;
  playerCardFinalDefence: number | null;
  playerBattleDamageTaken: number | null;
  playerEffectDamageTaken: number | null;
  playerHealAmount: number | null;
  opponentCardFinalAttack: number | null;
  opponentCardFinalDefence: number | null;
  opponentBattleDamageTaken: number | null;
  opponentEffectDamageTaken: number | null;
  opponentHealAmount: number | null;
  updatedUserHand:
    | (WarriorAndInfluencerCardDataType | TokenCardDataType)[]
    | null;
  updatedUserDeckCount: number | null;
  handleMobileSelectedCardChange: (
    value: WarriorAndInfluencerCardDataType | null
  ) => void;
  showPlayerCardAbilityOnMobile: boolean;
  setShowPlayerCardAbilityOnMobile: (value: boolean) => void;
  showOpponentCardAbilityOnMobile: boolean;
  updatedOpponentHandCount: number | null;
  statusEffects: StatusEffectData | null;
};

export const MobileTcgView = ({
  username,
  userState,
  opponentUsername,
  opponentState,
  selectedCard,
  opponentSelectedCard,
  onCardSubmit,
  roundStartTimer,
  countdownTimer,
  setCountdownTimer,
  hasOpponentDisconnected,
  selectedTokenCard,
  setSelectedTokenCard,
  opponentSelectedTokenCard,
  disableCardSelection,
  playerCardFinalAttack,
  playerCardFinalDefence,
  playerBattleDamageTaken,
  playerEffectDamageTaken,
  playerHealAmount,
  opponentCardFinalAttack,
  opponentCardFinalDefence,
  opponentBattleDamageTaken,
  opponentEffectDamageTaken,
  opponentHealAmount,
  updatedUserHand,
  updatedUserDeckCount,
  handleMobileSelectedCardChange,
  showPlayerCardAbilityOnMobile,
  setShowPlayerCardAbilityOnMobile,
  showOpponentCardAbilityOnMobile,
  updatedOpponentHandCount,
  statusEffects
}: MobileTcgViewProps) => {
  const defaultMaxHP = 500;

  useEffect(() => {
    if (roundStartTimer) {
      setCountdownTimer(
        differenceInSeconds(add(roundStartTimer, { seconds: 30 }), new Date())
      );
    }
  }, [roundStartTimer]);

  useEffect(() => {
    const countDown = setTimeout(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);

    return () => {
      clearTimeout(countDown);
    };
  }, [countdownTimer]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${gameBackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: `calc(100vh - 60px)`,
        width: '100%',
        pt: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ height: '8vh' }}>
        <OpponentHud
          opponentUsername={opponentUsername}
          hasOpponentDisconnected={hasOpponentDisconnected}
          opponentBattleDamageTaken={opponentBattleDamageTaken}
          opponentEffectDamageTaken={opponentEffectDamageTaken}
          opponentHealAmount={opponentHealAmount}
          defaultMaxHP={defaultMaxHP}
          opponentHp={opponentState?.hp ?? 500}
          statusEffects={statusEffects}
        />
      </Box>

      <Box sx={{ height: '35vh' }}>
        <OpponentCardBattleArea
          opponentSelectedCard={opponentSelectedCard}
          opponentSelectedTokenCard={opponentSelectedTokenCard}
          opponentCardFinalAttack={opponentCardFinalAttack}
          opponentCardFinalDefence={opponentCardFinalDefence}
          countdownTimer={countdownTimer}
          showOpponentCardAbilityOnMobile={showOpponentCardAbilityOnMobile}
          opponentDeckCount={opponentState?.deckCount ?? 0}
          opponentHandCount={opponentState?.handCount ?? 0}
          opponentGraveyardCount={opponentState?.graveyardCount ?? 0}
          opponentStatusEffects={opponentState?.statusEffect}
          updatedOpponentHandCount={updatedOpponentHandCount}
        />
      </Box>

      <Box sx={{ height: '35vh' }}>
        <PlayerCardBattleArea
          selectedCard={selectedCard}
          onCardSubmit={onCardSubmit}
          selectedTokenCard={selectedTokenCard}
          disableCardSelection={disableCardSelection}
          setSelectedTokenCard={setSelectedTokenCard}
          playerCardFinalAttack={playerCardFinalAttack}
          playerCardFinalDefence={playerCardFinalDefence}
          handleMobileSelectedCardChange={handleMobileSelectedCardChange}
          showPlayerCardAbilityOnMobile={showPlayerCardAbilityOnMobile}
          setShowPlayerCardAbilityOnMobile={setShowPlayerCardAbilityOnMobile}
          userStatusEffects={userState?.statusEffect}
          userDeckCount={userState?.deckCount ?? 0}
          updatedUserDeckCount={updatedUserDeckCount}
          userGraveyard={userState?.graveyard ?? []}
        />
      </Box>

      <Box
        sx={{
          height: '14vh'
        }}
      >
        <PlayerHand
          usersHand={userState?.hand ?? []}
          selectedCard={selectedCard}
          selectedTokenCard={selectedTokenCard}
          setSelectedTokenCard={setSelectedTokenCard}
          disableCardSelection={disableCardSelection}
          updatedUserHand={updatedUserHand}
          handleMobileSelectedCardChange={handleMobileSelectedCardChange}
        />
      </Box>

      <Box sx={{ height: '8vh', display: 'flex', alignItems: 'end' }}>
        <PlayerHud
          username={username}
          playerBattleDamageTaken={playerBattleDamageTaken}
          playerEffectDamageTaken={playerEffectDamageTaken}
          playerHealAmount={playerHealAmount}
          defaultMaxHP={defaultMaxHP}
          userHp={userState?.hp ?? 500}
          statusEffects={statusEffects}
        />
      </Box>
    </Box>
  );
};
