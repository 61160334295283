import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider
} from '@mui/material';
import {
  ConnectSocketAdditionalParams,
  DeckType,
  TcgDisconnectedData
} from '../../../../types/types';
import { useAuth } from '../../../../contexts/AuthContext';
import { Link as ReactRouterLink } from 'react-router-dom';
import { ReconnectToGameComponent } from './ReconnectToGameComponent';

type PlayButtonScreenProps = {
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  userDecks: DeckType[];
  selectedDeckName: string;
  setSelectedDeckName: (value: string) => void;
  username: string;
  resetGame: () => void;
};

export const PlayButtonScreen = ({
  connectSocket,
  userDecks,
  selectedDeckName,
  setSelectedDeckName,
  username,
  resetGame
}: PlayButtonScreenProps) => {
  const { accessToken } = useAuth();
  const [lobbyUsers, setLobbyUsers] = useState<number | 'error' | null>(null);
  const [usersInGame, setUsersInGame] = useState<number | 'error' | null>(null);
  const [userDisconnectedGame, setUserDisconnectedGame] =
    useState<TcgDisconnectedData>({
      hasUserDisconnected: false,
      gameType: null,
      timeLeft: null
    });

  useEffect(() => {
    fetchLobbyUsers();
    fetchUsersInGame();
    fetchDisconnectedUserGame();
  }, []);

  const fetchLobbyUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/globalLobby`
      );
      const data = await response.json();
      setLobbyUsers(data.globalLobbyCount);
    } catch (error) {
      setLobbyUsers('error');
    }
  };

  const fetchUsersInGame = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/gameRooms`
      );
      const data = await response.json();
      setUsersInGame(data.totalUsersPlaying);
    } catch (error) {
      setUsersInGame('error');
    }
  };

  const fetchDisconnectedUserGame = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/disconnectedUser/${username}`
      );
      const data = await response.json();
      setUserDisconnectedGame(data);
    } catch (error) {
      setUsersInGame('error');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: `calc(100vh - 4rem)`,
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: '-6rem',
          justifyContent: 'space-between'
        }}
      >
        {lobbyUsers !== null && lobbyUsers !== 'error' && (
          <Box
            sx={{
              width: '125px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '4px',
              p: '1rem',
              mr: '2rem'
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {lobbyUsers}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Waiting
            </Typography>
          </Box>
        )}
        {usersInGame !== null && usersInGame !== 'error' && (
          <Box
            sx={{
              width: '125px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '4px',
              p: '1rem'
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {usersInGame}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Playing
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          mt: '4rem',
          border: '1px solid white',
          borderRadius: '4px'
        }}
      >
        {userDisconnectedGame.hasUserDisconnected && (
          <ReconnectToGameComponent
            userDisconnectedGame={userDisconnectedGame}
            connectSocket={connectSocket}
            selectedDeckName={selectedDeckName}
            userDecks={userDecks}
            setSelectedDeckName={setSelectedDeckName}
            resetGame={resetGame}
          />
        )}
        {!userDisconnectedGame.hasUserDisconnected && userDecks.length > 0 && (
          <Box>
            <Box sx={{ p: '2rem' }}>
              <FormControl sx={{ width: '250px' }}>
                <InputLabel id="deck-select-label">Select Deck</InputLabel>
                <Select
                  labelId="deck-select-label"
                  id="deck-select"
                  label="Select Deck"
                  onChange={(e) => {
                    setSelectedDeckName(e.target.value);
                  }}
                  value={selectedDeckName}
                >
                  {userDecks.map((deck, index) => {
                    return (
                      <MenuItem key={index} value={deck.deckName}>
                        {deck.deckName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Divider />

            <Box sx={{ pt: '1rem' }}>
              <Typography
                variant="h4"
                fontWeight="bold"
                textAlign="center"
                sx={{ mb: '1rem' }}
              >
                VS
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  pb: '2rem'
                }}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() =>
                    connectSocket({
                      botGame: false,
                      deckName: selectedDeckName,
                      token: accessToken ?? ''
                    })
                  }
                  sx={{ width: '100px' }}
                  disabled={userDecks.length <= 0}
                >
                  Users
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  onClick={() =>
                    connectSocket({
                      botGame: true,
                      deckName: selectedDeckName,
                      token: accessToken ?? ''
                    })
                  }
                  sx={{ width: '100px' }}
                  disabled={userDecks.length <= 0}
                >
                  Bot
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {!userDisconnectedGame.hasUserDisconnected &&
          userDecks.length === 0 && (
            <Box sx={{ p: '2rem' }}>
              <Typography
                textAlign="center"
                variant="h6"
                fontWeight="bold"
                sx={{ mb: '1rem' }}
              >
                NO DECKS FOUND
              </Typography>
              <Button
                component={ReactRouterLink}
                to="/member-portal/nft"
                variant="contained"
                size="large"
              >
                Create a deck to play
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
};
