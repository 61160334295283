import React, { useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { add, differenceInSeconds } from 'date-fns';
import { StatusEffectData } from '../../../../types/types';

type MatchHUDProps = {
  username: string;
  userHp: number;
  opponentUsername: string;
  opponentHp: number;
  roundStartTimer: Date | null;
  countdownTimer: number;
  setCountdownTimer: (value: number) => void;
  hasOpponentDisconnected: boolean;
  playerBattleDamageTaken: number | null;
  playerEffectDamageTaken: number | null;
  playerHealAmount: number | null;
  opponentBattleDamageTaken: number | null;
  opponentEffectDamageTaken: number | null;
  opponentHealAmount: number | null;
  statusEffects: StatusEffectData | null;
};

export const MatchHUD = ({
  username,
  userHp,
  opponentUsername,
  opponentHp,
  roundStartTimer,
  countdownTimer,
  setCountdownTimer,
  hasOpponentDisconnected,
  playerBattleDamageTaken,
  playerEffectDamageTaken,
  playerHealAmount,
  opponentBattleDamageTaken,
  opponentEffectDamageTaken,
  opponentHealAmount,
  statusEffects
}: MatchHUDProps) => {
  const StyledPlayerHUD = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
    width: '35%',
    [theme.breakpoints.down('md')]: {
      width: '45%'
    }
  }));

  const StyledOpponentHUD = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
    width: '35%',
    [theme.breakpoints.down('md')]: {
      width: '45%'
    }
  }));

  const defaultMaxHP = 500;

  useEffect(() => {
    if (roundStartTimer) {
      setCountdownTimer(
        differenceInSeconds(add(roundStartTimer, { seconds: 30 }), new Date())
      );
    }
  }, [roundStartTimer]);

  useEffect(() => {
    const countDown = setTimeout(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);

    return () => {
      clearTimeout(countDown);
    };
  }, [countdownTimer]);

  const currentHealthPercentage = (health: number, maxHealth: number) => {
    return `${Math.floor((health * 100) / maxHealth)}%`;
  };

  return (
    <Box sx={{ height: '15vh' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: '0.3rem'
        }}
      >
        <StyledPlayerHUD>
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
              }}
            >
              {username}
            </Typography>

            <Box
              sx={{
                width: '100%',
                position: 'relative',
                height: '50px'
              }}
            >
              {/* Background bar */}
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  bgcolor: 'white',
                  borderRadius: '16px',
                  width: '100%'
                }}
              />
              {/* Health bar */}
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 2,
                  bgcolor: 'red',
                  borderRadius: '16px',
                  height: '100%',
                  width: currentHealthPercentage(userHp, defaultMaxHP)
                }}
              />
              {/* HP Text */}
              <Typography
                sx={{
                  position: 'absolute',
                  zIndex: 5,
                  left: '10px', // Moved to the left
                  top: '20%',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'white'
                }}
                variant="h6"
                fontWeight="bold"
              >
                {userHp}/{defaultMaxHP}
              </Typography>
              {/* Status Effects */}
              <Box
                sx={{
                  position: 'absolute',
                  right: '10px', // Align to the right of the bar
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px', // Spacing between images
                  zIndex: 5,
                  height: '100%' // Ensure it aligns with the bar's height
                }}
              >
                {statusEffects !== null &&
                  Object.entries(statusEffects.user)
                    .filter(
                      ([key, status]) =>
                        key === 'poison'
                          ? status.length > 0 // Check for poison's array length
                          : status.turnsLeft > 0 // Check for other statuses
                    )
                    .map(([key, status]) => (
                      <Box
                        key={key}
                        sx={{
                          position: 'relative',
                          width: '48px', // Adjust size for larger images
                          height: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {/* Status Effect Image */}
                        <Box
                          component="img"
                          src={
                            key === 'vulnerable'
                              ? require('../../../../assets/Card/TCGExtraImages/vulnerable.jpg')
                              : require(
                                  `../../../../assets/Card/TCGExtraImages/${key}.png`
                                )
                          }
                          alt={`${key} status`}
                          sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%', // Optional: make the images circular
                            objectFit: 'cover'
                          }}
                        />
                        {/* Overlay Number */}
                        <Typography
                          sx={{
                            position: 'absolute',
                            zIndex: 10,
                            color: 'white',
                            textShadow:
                              '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                            fontSize: '18px', // Adjust font size as needed
                            fontWeight: 'bold'
                          }}
                        >
                          {key === 'poison' ? status.length : status.turnsLeft}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>

            <Box
              sx={{
                visibility:
                  playerBattleDamageTaken !== null ||
                  playerEffectDamageTaken !== null ||
                  playerHealAmount !== null
                    ? ''
                    : 'hidden',
                height: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                Healing:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'green'
                }}
              >
                {playerHealAmount}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                EFT Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {playerEffectDamageTaken}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                BTL Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {playerBattleDamageTaken}
              </Typography>
            </Box>
          </Box>
        </StyledPlayerHUD>

        <Typography
          textAlign="center"
          variant="h2"
          fontWeight="bold"
          sx={{
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
          }}
        >
          {countdownTimer}
        </Typography>

        <StyledOpponentHUD>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: hasOpponentDisconnected
                  ? 'space-between'
                  : 'end'
              }}
            >
              {hasOpponentDisconnected && (
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: 'red' }}
                >
                  (Disconnected)
                </Typography>
              )}
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {opponentUsername}
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                position: 'relative',
                height: '50px'
              }}
            >
              {/* Background bar */}
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  bgcolor: 'white',
                  borderRadius: '16px',
                  width: '100%'
                }}
              />
              {/* Health bar */}
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 2,
                  bgcolor: 'red',
                  borderRadius: '16px',
                  height: '100%',
                  width: currentHealthPercentage(opponentHp, defaultMaxHP),
                  right: 0 // Align to the right so it shrinks from left to right
                }}
              />
              {/* Status Effects */}
              <Box
                sx={{
                  position: 'absolute',
                  left: '10px', // Align to the left of the bar
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px', // Spacing between images
                  zIndex: 5,
                  height: '100%' // Ensure it aligns with the bar's height
                }}
              >
                {statusEffects !== null &&
                  Object.entries(statusEffects.opponent)
                    .filter(
                      ([key, status]) =>
                        key === 'poison'
                          ? status.length > 0 // Check for poison's array length
                          : status.turnsLeft > 0 // Check for other statuses
                    )
                    .map(([key, status]) => (
                      <Box
                        key={key}
                        sx={{
                          position: 'relative',
                          width: '48px', // Adjust size for larger images
                          height: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        {/* Status Effect Image */}
                        <Box
                          component="img"
                          src={
                            key === 'vulnerable'
                              ? require('../../../../assets/Card/TCGExtraImages/vulnerable.jpg')
                              : require(
                                  `../../../../assets/Card/TCGExtraImages/${key}.png`
                                )
                          }
                          alt={`${key} status`}
                          sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%', // Optional: make the images circular
                            objectFit: 'cover'
                          }}
                        />
                        {/* Overlay Number */}
                        <Typography
                          sx={{
                            position: 'absolute',
                            zIndex: 10,
                            color: 'white',
                            textShadow:
                              '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                            fontSize: '18px', // Adjust font size as needed
                            fontWeight: 'bold'
                          }}
                        >
                          {key === 'poison' ? status.length : status.turnsLeft}
                        </Typography>
                      </Box>
                    ))}
              </Box>
              {/* HP Text */}
              <Typography
                sx={{
                  position: 'absolute',
                  zIndex: 5,
                  right: '10px', // Moved to the right
                  top: '20%',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'white'
                }}
                variant="h6"
                fontWeight="bold"
              >
                {opponentHp}/{defaultMaxHP}
              </Typography>
            </Box>

            <Box
              sx={{
                visibility:
                  opponentBattleDamageTaken !== null ||
                  opponentEffectDamageTaken !== null ||
                  opponentHealAmount !== null
                    ? ''
                    : 'hidden',
                height: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                Healing:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'green'
                }}
              >
                {opponentHealAmount}
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                EFT Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {opponentEffectDamageTaken}
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                BTL Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {opponentBattleDamageTaken}
              </Typography>
            </Box>
          </Box>
        </StyledOpponentHUD>
      </Box>
    </Box>
  );
};
