import React from 'react';
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EastIcon, WestIcon } from '../../../../constants/icons';
import {
  TokenCardDataType,
  WarriorAndInfluencerCardDataType
} from '../../../../types/types';

type CardBattleAreaProps = {
  selectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  opponentSelectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  onCardSubmit: () => void;
  selectedTokenCard: TokenCardDataType | 'noCard' | null;
  opponentSelectedTokenCard: TokenCardDataType | 'noCard' | null;
  disableCardSelection: boolean;
  setSelectedCard: (
    card: WarriorAndInfluencerCardDataType | 'noCard' | null
  ) => void;
  setSelectedTokenCard: (card: TokenCardDataType | 'noCard' | null) => void;
  disableCardHoverInfo: boolean;
  setDisbaleCardHoverInfo: (value: boolean) => void;
  playerCardFinalAttack: number | null;
  playerCardFinalDefence: number | null;
  opponentCardFinalAttack: number | null;
  opponentCardFinalDefence: number | null;
};

export const CardBattleArea = ({
  selectedCard,
  opponentSelectedCard,
  onCardSubmit,
  selectedTokenCard,
  opponentSelectedTokenCard,
  disableCardSelection,
  setSelectedCard,
  setSelectedTokenCard,
  disableCardHoverInfo,
  setDisbaleCardHoverInfo,
  playerCardFinalAttack,
  playerCardFinalDefence,
  opponentCardFinalAttack,
  opponentCardFinalDefence
}: CardBattleAreaProps) => {
  return (
    <Box sx={{ height: '47vh', pt: '0.5rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              mr: '2rem',
              border:
                selectedCard !== null &&
                selectedCard !== 'noCard' &&
                selectedTokenCard !== null &&
                selectedTokenCard !== 'noCard' &&
                selectedCard.faction !== selectedTokenCard.faction
                  ? '5px solid red'
                  : '5px solid transparent',
              borderRadius: '4px'
            }}
          >
            {selectedTokenCard !== null && selectedTokenCard !== 'noCard' && (
              <Box
                sx={{
                  position: 'relative',
                  width: '12rem',
                  height: '18rem',
                  borderRadius: '4px'
                }}
              >
                <Box
                  component="img"
                  src={require(
                    `../../../../assets/Card/Token/${selectedTokenCard.name}/${selectedTokenCard.rarity}.png`
                  )}
                  sx={{
                    position: 'absolute',
                    width: '12rem',
                    height: '18rem',
                    borderRadius: '4px',
                    zIndex: 1
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    visibility: !selectedTokenCard ? 'hidden' : '',
                    width: '12rem',
                    height: '18rem',
                    borderRadius: '4px',
                    zIndex: 2
                  }}
                >
                  <Box
                    sx={{
                      height: '17rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'end'
                    }}
                  >
                    {!disableCardSelection && (
                      <Button
                        variant="contained"
                        onClick={() => setSelectedTokenCard(null)}
                      >
                        Unselect
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {selectedTokenCard === 'noCard' && (
              <Box
                sx={{
                  bgcolor: 'divider',
                  width: '12rem',
                  height: '18rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  No Buff Card Selected
                </Typography>
              </Box>
            )}
            {selectedTokenCard === null && (
              <Box
                sx={{ bgcolor: 'divider', width: '12rem', height: '18rem' }}
              />
            )}
          </Box>

          <Box sx={{ border: '5px solid transparent' }}>
            {selectedCard !== null && selectedCard !== 'noCard' && (
              <Tooltip
                placement="top"
                title={
                  <Typography variant="subtitle1">
                    {selectedCard.abilityDescription}
                  </Typography>
                }
              >
                <Box
                  sx={{
                    bgcolor: 'black',
                    position: 'relative',
                    width: '12rem',
                    height: '18rem',
                    borderRadius: '8px'
                  }}
                >
                  <Box
                    component="img"
                    src={require(
                      `../../../../assets/Card/Warrior/${selectedCard.name}/${selectedCard.rarity}.png`
                    )}
                    sx={{
                      position: 'absolute',
                      width: '12rem',
                      height: '18rem',
                      borderRadius: '4px',
                      zIndex: 1
                    }}
                  />

                  <Box
                    sx={{
                      position: 'absolute',
                      visibility: !selectedCard ? 'hidden' : '',
                      width: '12rem',
                      height: '18rem',
                      borderRadius: '4px',
                      zIndex: 2
                    }}
                  >
                    <Box
                      sx={{
                        height: '17rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end'
                      }}
                    >
                      {!disableCardSelection && (
                        <Button
                          variant="contained"
                          onClick={() => setSelectedCard(null)}
                        >
                          Unselect
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Tooltip>
            )}
            {selectedCard === 'noCard' && (
              <Box
                sx={{
                  bgcolor: 'divider',
                  width: '12rem',
                  height: '18rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  No Card Selected
                </Typography>
              </Box>
            )}
            {selectedCard === null && (
              <Box
                sx={{ bgcolor: 'divider', width: '12rem', height: '18rem' }}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginX: '1rem',
            justifyContent: 'center'
          }}
        >
          <EastIcon fontSize="large" />
          <WestIcon fontSize="large" />
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Box sx={{ mr: '2rem', border: '5px solid transparent' }}>
            {opponentSelectedCard !== null &&
              opponentSelectedCard !== 'noCard' && (
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="subtitle1">
                      {opponentSelectedCard.abilityDescription}
                    </Typography>
                  }
                >
                  <Box
                    component="img"
                    src={require(
                      `../../../../assets/Card/Warrior/${opponentSelectedCard.name}/${opponentSelectedCard.rarity}.png`
                    )}
                    sx={{ width: '12rem', height: '18rem' }}
                  />
                </Tooltip>
              )}
            {opponentSelectedCard === 'noCard' && (
              <Box
                sx={{
                  bgcolor: 'divider',
                  width: '12rem',
                  height: '18rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  No Card Selected
                </Typography>
              </Box>
            )}
            {opponentSelectedCard === null && (
              <Box
                sx={{ bgcolor: 'divider', width: '12rem', height: '18rem' }}
              />
            )}
          </Box>
          <Box sx={{ border: '5px solid transparent' }}>
            {opponentSelectedTokenCard !== null &&
              opponentSelectedTokenCard !== 'noCard' && (
                <Box
                  component="img"
                  src={require(
                    `../../../../assets/Card/Token/${opponentSelectedTokenCard.name}/${opponentSelectedTokenCard.rarity}.png`
                  )}
                  sx={{ width: '12rem', height: '18rem' }}
                />
              )}
            {opponentSelectedTokenCard === 'noCard' && (
              <Box
                sx={{
                  bgcolor: 'divider',
                  width: '12rem',
                  height: '18rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  No Buff Card Selected
                </Typography>
              </Box>
            )}
            {opponentSelectedTokenCard === null && (
              <Box
                sx={{ bgcolor: 'divider', width: '12rem', height: '18rem' }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Grid container>
        <Grid xs={3.5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              pt: '1rem'
            }}
          >
            {selectedCard !== null &&
              selectedCard !== 'noCard' &&
              selectedTokenCard !== null &&
              selectedTokenCard !== 'noCard' &&
              selectedCard.faction !== selectedTokenCard.faction && (
                <Box
                  sx={{
                    width: '15rem',
                    border: '1px solid black',
                    backgroundColor: 'red',
                    mr: '1rem',
                    py: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    sx={{
                      textShadow:
                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                    }}
                  >
                    Factions do not match
                  </Typography>
                </Box>
              )}
          </Box>
        </Grid>
        <Grid xs={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: '1rem'
            }}
          >
            <Box
              sx={{
                visibility:
                  playerCardFinalAttack && playerCardFinalDefence
                    ? ''
                    : 'hidden',
                border: '1px solid black',
                display: 'flex',
                width: '12rem',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'lightblue'
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  mr: '0.2rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                ATK:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  mr: '1rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {playerCardFinalAttack}
              </Typography>
              <Typography
                fontWeight="bold"
                sx={{
                  mr: '0.2rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                DEF:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {playerCardFinalDefence}
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => onCardSubmit()}
              sx={{ mx: '2rem' }}
              disabled={disableCardSelection}
              size="large"
            >
              Ready
            </Button>
            <Box
              sx={{
                visibility:
                  opponentCardFinalAttack && opponentCardFinalDefence
                    ? ''
                    : 'hidden',
                border: '1px solid black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '12rem',
                backgroundColor: 'lightblue'
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  mr: '0.2rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                ATK:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  mr: '1rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {opponentCardFinalAttack}
              </Typography>
              <Typography
                fontWeight="bold"
                sx={{
                  mr: '0.2rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                DEF:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {opponentCardFinalDefence}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={3.5}>
          <Box
            sx={{
              width: '15rem',
              pt: '1rem',
              ml: '1rem',
              border: '1px solid transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={disableCardHoverInfo}
                  onChange={() => {
                    setDisbaleCardHoverInfo(!disableCardHoverInfo);
                  }}
                />
              }
              label={
                <Typography
                  variant="h6"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  Disable Card Hover Info
                </Typography>
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
