import React from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  Tooltip,
  IconButton
} from '@mui/material';
import {
  WarriorAndInfluencerCardDataType,
  TokenCardDataType,
  CardGamePlayerStatusEffects
} from '../../../../../types/types';
import { CloseIcon } from '../../../../../constants/icons';

type PlayerCardBattleAreaProps = {
  selectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  onCardSubmit: () => void;
  selectedTokenCard: TokenCardDataType | 'noCard' | null;
  disableCardSelection: boolean;
  setSelectedTokenCard: (card: TokenCardDataType | 'noCard' | null) => void;
  playerCardFinalAttack: number | null;
  playerCardFinalDefence: number | null;
  handleMobileSelectedCardChange: (
    card: WarriorAndInfluencerCardDataType | null
  ) => void;
  showPlayerCardAbilityOnMobile: boolean;
  setShowPlayerCardAbilityOnMobile: (value: boolean) => void;
  userDeckCount: number;
  updatedUserDeckCount: number | null;
  userGraveyard: (WarriorAndInfluencerCardDataType | TokenCardDataType)[];
  userStatusEffects: CardGamePlayerStatusEffects | undefined;
};

export const PlayerCardBattleArea = ({
  selectedCard,
  onCardSubmit,
  selectedTokenCard,
  disableCardSelection,
  setSelectedTokenCard,
  playerCardFinalAttack,
  playerCardFinalDefence,
  handleMobileSelectedCardChange,
  showPlayerCardAbilityOnMobile,
  setShowPlayerCardAbilityOnMobile,
  userDeckCount,
  updatedUserDeckCount,
  userGraveyard,
  userStatusEffects
}: PlayerCardBattleAreaProps) => {
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={4}>
        <Box
          sx={{
            borderRadius: '4px'
          }}
        >
          <Box sx={{ px: '0.5rem', pb: '0.5rem' }}>
            <Box
              sx={{
                width: '100%',
                border: '1px solid white',
                display: 'flex',
                justifyContent: 'space-evenly',
                bgcolor: 'black'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '45%'
                }}
              >
                <Typography
                  fontWeight="bold"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  A:
                </Typography>
                <Typography
                  fontWeight="bold"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  {playerCardFinalAttack}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '45%'
                }}
              >
                <Typography
                  fontWeight="bold"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  D:
                </Typography>
                <Typography
                  fontWeight="bold"
                  sx={{
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  {playerCardFinalDefence}
                </Typography>
              </Box>
            </Box>
          </Box>

          {selectedTokenCard !== null && selectedTokenCard !== 'noCard' && (
            <Box
              sx={{
                position: 'relative',
                borderRadius: '4px',
                width: '100%',
                display: 'flex',
                justifyContent: 'end'
              }}
            >
              <img
                src={require(
                  `../../../../../assets/Card/Token/${selectedTokenCard.name}/${selectedTokenCard.rarity}.png`
                )}
                style={{
                  borderRadius: '4px',
                  width: '75%',
                  height: 'auto',
                  zIndex: 1
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  visibility: !selectedTokenCard ? 'hidden' : '',
                  borderRadius: '4px',
                  zIndex: 2
                }}
              >
                <Box>
                  {!disableCardSelection && (
                    <IconButton
                      color="error"
                      size="large"
                      onClick={() => setSelectedTokenCard(null)}
                      sx={{ zIndex: 200 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ border: '5px solid transparent' }}>
          {selectedCard !== null && selectedCard !== 'noCard' && (
            <Tooltip
              placement="top"
              title={
                <Typography variant="subtitle1">
                  {selectedCard.abilityDescription}
                </Typography>
              }
            >
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '4px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end'
                }}
              >
                <img
                  src={require(
                    `../../../../../assets/Card/Warrior/${selectedCard.name}/${selectedCard.rarity}.png`
                  )}
                  style={{
                    borderRadius: '4px',
                    width: '100%',
                    height: 'auto',
                    zIndex: 1
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    visibility: !selectedCard ? 'hidden' : '',
                    borderRadius: '4px',
                    zIndex: 2
                  }}
                >
                  <Box>
                    {!disableCardSelection && (
                      <IconButton
                        color="error"
                        size="large"
                        onClick={() => handleMobileSelectedCardChange(null)}
                        sx={{ zIndex: 200 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ height: '100%' }}>
        <Box sx={{ height: '25%' }}>
          <Button
            variant="contained"
            onClick={() => onCardSubmit()}
            sx={{ mx: '1rem' }}
            disabled={disableCardSelection}
            size="large"
          >
            Ready
          </Button>
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '75%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            position: 'relative' // Enable absolute positioning for children
          }}
        >
          {!showPlayerCardAbilityOnMobile && (
            <>
              <img
                className="deck"
                src={require(`../../../../../assets/Card/TCGExtraImages/1.png`)}
                style={{ width: '100%', height: 'auto' }}
              />
              {/* Bottom-left Typography */}
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute', // Positioning relative to the container
                  bottom: '0%', // Position at the bottom
                  left: '25%', // Position on the left
                  color: 'white',
                  textShadow: '0px 0px 5px black', // Optional shadow
                  fontWeight: 'bold'
                }}
              >
                DK:{' '}
                {updatedUserDeckCount !== null
                  ? updatedUserDeckCount
                  : userDeckCount}
              </Typography>

              {/* Top-right Typography */}
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute', // Positioning relative to the container
                  top: '37%', // Position at the top
                  right: '25%', // Position on the right
                  color: 'white',
                  textShadow: '0px 0px 5px black', // Optional shadow
                  fontWeight: 'bold'
                }}
              >
                GY: {userGraveyard.length}
              </Typography>
            </>
          )}

          {selectedCard !== null &&
            selectedCard !== 'noCard' &&
            showPlayerCardAbilityOnMobile && (
              <Box
                sx={{
                  zIndex: 10,
                  border: '1px solid white',
                  bgcolor: 'black',
                  borderRadius: '4px'
                }}
              >
                <Typography>{selectedCard.abilityDescription}</Typography>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => setShowPlayerCardAbilityOnMobile(false)}
                    size="small"
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            )}
        </Box>
      </Grid>
    </Grid>
  );
};
