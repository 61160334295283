import React from 'react';
import { Grid, Box, Typography, Tooltip } from '@mui/material';
import {
  WarriorAndInfluencerCardDataType,
  TokenCardDataType,
  CardGamePlayerStatusEffects
} from '../../../../../types/types';

type OpponentCardBattleAreaProps = {
  opponentSelectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  opponentSelectedTokenCard: TokenCardDataType | 'noCard' | null;
  opponentCardFinalAttack: number | null;
  opponentCardFinalDefence: number | null;
  countdownTimer: number;
  showOpponentCardAbilityOnMobile: boolean;
  opponentDeckCount: number;
  opponentHandCount: number;
  opponentGraveyardCount: number;
  opponentStatusEffects: CardGamePlayerStatusEffects | undefined;
  updatedOpponentHandCount: number | null;
};

export const OpponentCardBattleArea = ({
  opponentSelectedCard,
  opponentSelectedTokenCard,
  opponentCardFinalAttack,
  opponentCardFinalDefence,
  countdownTimer,
  showOpponentCardAbilityOnMobile,
  opponentDeckCount,
  opponentHandCount,
  opponentGraveyardCount,
  opponentStatusEffects,
  updatedOpponentHandCount
}: OpponentCardBattleAreaProps) => {
  return (
    <Grid container sx={{ height: '100%', pb: '0.5rem' }}>
      <Grid
        item
        xs={4}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end'
        }}
      >
        <Box
          sx={{
            border: '5px solid transparent',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'end'
          }}
        >
          {opponentSelectedTokenCard !== null &&
            opponentSelectedTokenCard !== 'noCard' && (
              <img
                src={require(
                  `../../../../../assets/Card/Token/${opponentSelectedTokenCard.name}/${opponentSelectedTokenCard.rarity}.png`
                )}
                style={{
                  width: '75%',
                  height: 'auto'
                }}
              />
            )}
        </Box>
        <Box sx={{ px: '0.5rem', width: '100%', pt: '0.5rem' }}>
          <Box
            sx={{
              marginTop: 'auto',
              width: '100%',
              border: '1px solid white',
              display: 'flex',
              justifyContent: 'space-evenly',
              bgcolor: 'black'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '45%'
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                A:
              </Typography>
              <Typography
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {opponentCardFinalAttack}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '45%'
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                D:
              </Typography>
              <Typography
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {opponentCardFinalDefence}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end'
        }}
      >
        <Box>
          {opponentSelectedCard !== null &&
            opponentSelectedCard !== 'noCard' && (
              <Tooltip
                placement="top"
                title={
                  <Typography variant="subtitle1">
                    {opponentSelectedCard.abilityDescription}
                  </Typography>
                }
              >
                <img
                  src={require(
                    `../../../../../assets/Card/Warrior/${opponentSelectedCard.name}/${opponentSelectedCard.rarity}.png`
                  )}
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </Tooltip>
            )}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            position: 'relative'
          }}
        >
          {!showOpponentCardAbilityOnMobile && (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative'
                }}
              >
                {updatedOpponentHandCount !== null ? (
                  <>
                    {updatedOpponentHandCount > 0 && (
                      <img
                        className="deck"
                        src={require(
                          `../../../../../assets/Card/TCGExtraImages/${updatedOpponentHandCount}.png`
                        )}
                        style={{ width: '70%', height: 'auto' }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {opponentHandCount > 0 && (
                      <img
                        className="deck"
                        src={require(
                          `../../../../../assets/Card/TCGExtraImages/${opponentHandCount}.png`
                        )}
                        style={{ width: '70%', height: 'auto' }}
                      />
                    )}
                  </>
                )}

                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute', // Positioning relative to the container
                    top: '50%', // Position at the bottom
                    left: '50%', // Position on the left
                    color: 'white',
                    textShadow: '0px 0px 5px black', // Optional shadow
                    fontWeight: 'bold'
                  }}
                >
                  {updatedOpponentHandCount !== null
                    ? updatedOpponentHandCount
                    : opponentHandCount}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative' // Enable absolute positioning for children
                }}
              >
                <img
                  className="deck"
                  src={require(
                    `../../../../../assets/Card/TCGExtraImages/1.png`
                  )}
                  style={{ width: '100%', height: 'auto' }}
                />

                {/* Bottom-left Typography */}
                <Typography
                  variant="h6"
                  sx={{
                    position: 'absolute', // Positioning relative to the container
                    bottom: '0%', // Position at the bottom
                    left: '25%', // Position on the left
                    color: 'white',
                    textShadow: '0px 0px 5px black', // Optional shadow
                    fontWeight: 'bold'
                  }}
                >
                  DK: {opponentDeckCount}
                </Typography>

                {/* Top-right Typography */}
                <Typography
                  variant="h6"
                  sx={{
                    position: 'absolute', // Positioning relative to the container
                    top: '0', // Position at the top
                    right: '25%', // Position on the right
                    color: 'white',
                    textShadow: '0px 0px 5px black', // Optional shadow
                    fontWeight: 'bold'
                  }}
                >
                  GY: {opponentGraveyardCount}
                </Typography>
              </Box>
            </Box>
          )}

          {opponentSelectedCard !== null &&
            opponentSelectedCard !== 'noCard' &&
            showOpponentCardAbilityOnMobile && (
              <Box
                sx={{
                  zIndex: 10,
                  border: '1px solid white',
                  bgcolor: 'black',
                  borderRadius: '4px'
                }}
              >
                <Typography variant="subtitle1">
                  {opponentSelectedCard.abilityDescription}
                </Typography>
              </Box>
            )}
        </Box>
        <Box>
          <Typography
            textAlign="center"
            variant="h4"
            fontWeight="bold"
            sx={{
              textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
            }}
          >
            {countdownTimer}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
