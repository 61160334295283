import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import {
  WarriorAndInfluencerCardDataType,
  TokenCardDataType
} from '../../../../../types/types';

type PlayerHandProps = {
  usersHand: (WarriorAndInfluencerCardDataType | TokenCardDataType)[];
  selectedCard: WarriorAndInfluencerCardDataType | 'noCard' | null;
  selectedTokenCard: TokenCardDataType | 'noCard' | null;
  setSelectedTokenCard: (card: TokenCardDataType | 'noCard' | null) => void;
  disableCardSelection: boolean;
  updatedUserHand:
    | (WarriorAndInfluencerCardDataType | TokenCardDataType)[]
    | null;
  handleMobileSelectedCardChange: (
    value: WarriorAndInfluencerCardDataType | null
  ) => void;
};

export const PlayerHand = ({
  usersHand,
  selectedCard,
  selectedTokenCard,
  setSelectedTokenCard,
  disableCardSelection,
  updatedUserHand,
  handleMobileSelectedCardChange
}: PlayerHandProps) => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        {updatedUserHand !== null
          ? updatedUserHand.map((cardData, index) => {
              return (
                <Box key={index}>
                  {cardData.type === 'Warrior' && (
                    <Tooltip
                      placement="top"
                      title={
                        <Typography variant="subtitle1">
                          {cardData.abilityDescription}
                        </Typography>
                      }
                      sx={{ height: '100%' }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          borderRadius: '4px',
                          display: selectedCard === cardData ? 'none' : '',
                          zIndex: 0,
                          objectFit: 'contain'
                        }}
                        onClick={() => {
                          if (!disableCardSelection) {
                            handleMobileSelectedCardChange(cardData);
                          }
                        }}
                      >
                        <img
                          src={require(
                            `../../../../../assets/Card/Warrior/${cardData.name}/${cardData.rarity}.png`
                          )}
                          style={{
                            width: '55px',
                            height: 'auto',
                            borderRadius: '4px',
                            zIndex: index + 2,
                            objectFit: 'contain'
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {cardData.type === 'Token' && (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '4px',
                        display: selectedTokenCard === cardData ? 'none' : '',
                        zIndex: 0,
                        height: '100%',
                        objectFit: 'contain'
                      }}
                      onClick={() => {
                        if (!disableCardSelection) {
                          setSelectedTokenCard(cardData);
                        }
                      }}
                    >
                      <img
                        src={require(
                          `../../../../../assets/Card/Token/${cardData.name}/${cardData.rarity}.png`
                        )}
                        style={{
                          width: '55px',
                          height: 'auto',
                          borderRadius: '4px',
                          zIndex: index + 2,
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  )}
                </Box>
              );
            })
          : usersHand.map((cardData, index) => {
              return (
                <Box key={index}>
                  {cardData.type === 'Warrior' && (
                    <Tooltip
                      placement="top"
                      title={
                        <Typography variant="subtitle1">
                          {cardData.abilityDescription}
                        </Typography>
                      }
                      sx={{ height: '100%' }}
                    >
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          borderRadius: '4px',
                          display: selectedCard === cardData ? 'none' : '',
                          zIndex: 0,
                          objectFit: 'contain'
                        }}
                        onClick={() => {
                          if (!disableCardSelection) {
                            handleMobileSelectedCardChange(cardData);
                          }
                        }}
                      >
                        <img
                          src={require(
                            `../../../../../assets/Card/Warrior/${cardData.name}/${cardData.rarity}.png`
                          )}
                          style={{
                            width: '55px',
                            height: 'auto',
                            borderRadius: '4px',
                            zIndex: index + 2,
                            objectFit: 'contain'
                          }}
                        />
                      </Box>
                    </Tooltip>
                  )}
                  {cardData.type === 'Token' && (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        borderRadius: '4px',
                        display: selectedTokenCard === cardData ? 'none' : '',
                        zIndex: 0,
                        height: '100%',
                        objectFit: 'contain'
                      }}
                      onClick={() => {
                        if (!disableCardSelection) {
                          setSelectedTokenCard(cardData);
                        }
                      }}
                    >
                      <img
                        src={require(
                          `../../../../../assets/Card/Token/${cardData.name}/${cardData.rarity}.png`
                        )}
                        style={{
                          width: '55px',
                          height: 'auto',
                          borderRadius: '4px',
                          zIndex: index + 2,
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};
