import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StatusEffectData } from '../../../../../types/types';

type PlayerHudProps = {
  username: string;
  playerBattleDamageTaken: number | null;
  playerEffectDamageTaken: number | null;
  playerHealAmount: number | null;
  defaultMaxHP: number;
  userHp: number;
  statusEffects: StatusEffectData | null;
};

export const PlayerHud = ({
  username,
  playerBattleDamageTaken,
  playerEffectDamageTaken,
  playerHealAmount,
  defaultMaxHP,
  userHp,
  statusEffects
}: PlayerHudProps) => {
  const StyledHUD = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
    width: '100%'
  }));

  const currentHealthPercentage = (health: number, maxHealth: number) => {
    return `${Math.floor((health * 100) / maxHealth)}%`;
  };

  return (
    <StyledHUD>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ width: '25%' }}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
              }}
              noWrap={true}
            >
              {username}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              visibility:
                playerBattleDamageTaken !== null ||
                playerEffectDamageTaken !== null ||
                playerHealAmount !== null
                  ? ''
                  : 'hidden',
              height: '2rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '75%'
            }}
          >
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
              }}
            >
              HL:
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                color: 'green'
              }}
            >
              {playerHealAmount}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
              }}
            >
              E DMG:
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                color: 'red'
              }}
            >
              {playerEffectDamageTaken}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
              }}
            >
              B DMG:
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                color: 'red'
              }}
            >
              {playerBattleDamageTaken}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            position: 'relative',
            height: '30px'
          }}
        >
          {/* Background bar */}
          <Box
            sx={{
              position: 'absolute',
              zIndex: 1,
              height: '100%',
              bgcolor: 'white',
              borderRadius: '4px',
              width: '100%'
            }}
          />
          {/* Health bar */}
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              bgcolor: 'red',
              borderRadius: '4px',
              height: '100%',
              width: currentHealthPercentage(userHp, defaultMaxHP)
            }}
          />
          {/* HP Text */}
          <Typography
            sx={{
              position: 'absolute',
              zIndex: 5,
              left: '10px', // Moved to the left
              textShadow:
                '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
              color: 'white'
            }}
            variant="h6"
            fontWeight="bold"
          >
            {userHp}/{defaultMaxHP}
          </Typography>
          {/* Status Effects */}
          <Box
            sx={{
              position: 'absolute',
              right: '10px', // Align to the right of the bar
              display: 'flex',
              alignItems: 'center',
              gap: '4px', // Spacing between images
              zIndex: 5
            }}
          >
            {statusEffects !== null &&
              Object.entries(statusEffects.user)
                .filter(
                  ([key, status]) =>
                    key === 'poison'
                      ? status.length > 0 // Check for poison's array length
                      : status.turnsLeft > 0 // Check for other statuses
                )
                .map(([key, status]) => (
                  <Box
                    key={key}
                    sx={{
                      position: 'relative',
                      width: '28px', // Adjust size as needed
                      height: '28px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {/* Status Effect Image */}
                    <Box
                      component="img"
                      src={
                        key === 'vulnerable'
                          ? require('../../../../../assets/Card/TCGExtraImages/vulnerable.jpg')
                          : require(
                              `../../../../../assets/Card/TCGExtraImages/${key}.png`
                            )
                      }
                      alt={`${key} status`}
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%', // Optional: make the images circular
                        objectFit: 'cover'
                      }}
                    />
                    {/* Overlay Number */}
                    <Typography
                      sx={{
                        position: 'absolute',
                        zIndex: 10,
                        color: 'white',
                        textShadow:
                          '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                        fontSize: '16px', // Adjust font size as needed
                        fontWeight: 'bold'
                      }}
                    >
                      {key === 'poison' ? status.length : status.turnsLeft}
                    </Typography>
                  </Box>
                ))}
          </Box>
        </Box>
      </Box>
    </StyledHUD>
  );
};
